<template>
  <Layout>
    <PageHeader title="Add / Edit Members" :items="items"> </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <b-form-group>
                <b-form-group class="col-12 pl-0">
                  <div
                    class="my-1 d-flex justify-content-between align-items-center"
                  >
                    <label label-for="in-forum" class="mb-1"
                      >Member Tagging <span style="color: red">*</span>
                    </label>
                  </div>
                  <multiselect
                    @search-change="fetchMemberList"
                    id="member"
                    v-model="form.member_tagging"
                    :options="memberList"
                    :multiple="true"
                    track-by="member_id"
                    label="fnameAndLname"
                    placeholder="Type here to search"
                    :class="{
                      'is-invalid':
                        submitted && $v.form.member_tagging.$invalid,
                    }"
                  >
                    <span slot="noOptions"> Type here to search </span>
                  </multiselect>
                  <div
                    v-if="submitted && !$v.form.member_tagging.required"
                    class="invalid-feedback"
                  >
                    Member Tagging is required.
                  </div>
                </b-form-group>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
                >Save Data
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import { required } from "vuelidate/lib/validators";
import hospitalMixin from "@/mixins/ModuleJs/member-tagging";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mixins: [MixinRequest, hospitalMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      member_tagging: { required },
    },
  },
  created() {
    this.fetchSingleHospital(this.$route.params.id);
  },
};
</script>
