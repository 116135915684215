import _ from "lodash";
import { clinics, members } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      params: "",
      key: 0,
      member_tagging: [],
      member_tagging_fields: [
        {
          key: "id",
        },
        {
          key: "member",
        },
      ],
      form: {
        member_tagging: [],
      },
      memberList: [],
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchMembersList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getMemberTagging}/${this.$route.params.id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.member_tagging = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map((member) => ({
            member_id: member.id,
            fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
          }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),
    async fetchSingleHospital(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getMemberTagging}/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData?.length > 0) {
            this.form.member_tagging = responseData?.map((item) => {
              return {
                member_id: item.id,
                fnameAndLname: item.member,
              };
            });
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = clinics?.getMemberTagging + "/" + this.$route.params.id;
        let dataAppend = new FormData();
        let memberTagging = [];
        if (this.form.member_tagging.length > 0) {
          this.form.member_tagging.map((item) => {
            memberTagging.push(item.member_id);
          });
        }
        dataAppend.append("member_tagging", JSON.stringify(memberTagging));
        dataAppend.append("clinic_id", this.$route.params.id);
        dataAppend.append("_method", "put");
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/clinic/edit-clinic/${this.$route.params.id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
};
